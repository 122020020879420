import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import {motion} from 'framer-motion';
const Hero = () => {
  const transition = {type: 'spring', duration : 3}
  return (
    <div className='hero'>
      <div className='blur blur-h'></div>
     <div className='left-h'>
        <Header/>
{/*the best ad*/}
        <div className='the-best-ad'>
            <motion.div
             initial={{left:'238px'}}
             whileInView={{left:'8px'}}
             transition={{...transition, type:'tween'}}
             ></motion.div>
            <span>the best fitmess club in the town</span>
        </div>

        {/*Hero heading*/}
        <div className='hero-text'>

            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your</span>
            </div>
            <div>
                <span>Ideal body</span>
            </div>
            <div >
              <span> In here we will help you to shape and build your ideal body and live up your life to fullest</span>
            </div>

        </div>

        {/* figures*/}
        <div className='figures'>
          <div>
            <span>+140</span><span>Expert Coaches</span>
            </div>
          <div>
            <span>+978</span><span>Members Joined</span>
            </div>
          <div>
            <span>+50</span><span>Fitness Program</span>
            </div>
        </div>

         {/*Hero button*/}
     <div className="hero-buttons">
      <buttons className="btn">Get Started</buttons>
      <buttons className="btn">Learn More</buttons>
     </div>
     </div>

    
     <div className='right-h'>
      <button className="btn">Join Now</button>

      <motion.div 
      whileInView={{right:'4rem'}}
      transition={transition}
      initial={{right:'-1rem'}}
      className="heart-rate">
        <img src={Heart} alt="" />
        <span>Heart Rate</span>
        <span>116 bpm</span>
      </motion.div>

      {/*hero images*/}
      
      <img src={hero_image} className='hero-image' alt="" />
      <motion.img 
      initial={{right:'11rem'}}
      transition={transition}
      whileInView={{right:'20rem'}}
      src={hero_image_back} className='hero-image-back' alt="" />

     
      
    
      {/*Calories */}
      <motion.div
      initial={{right:'37rem'}}
      whileInView={{right:'28rem'}}
      transition={transition}
      className="calories">
        <img src={Calories} alt="" />
        <div>
        <span>Calories Burned</span>
        <span>220 kkcl</span>
        </div>
       
      </motion.div>

    </div>
    </div>
  )
}

export default Hero
