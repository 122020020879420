import React from 'react'
import './Footer.css'
import linkedin from '../../assets/linkedin.png';
import logo from '../../assets/logo.png';
import instagram from '../../assets/instagram.png';
import github from '../../assets/github.png';

const Footer = () => {
  return (
    <div className='Footer-container'>
      
      <hr />
      <div className="footer">
        <div className='socials'>
            <img src={github} alt="" />
            <img src={instagram} alt="" />
            <img src={linkedin} alt="" />
        </div>
      
      <div className="logo-f">
        <img src={logo} alt="" />
      </div>
      </div>
      
      <div className="blur blur1"></div>
        <div className="blur blur2"></div>

    </div>
  )
}

export default Footer
